/* Global reset for margin, padding, and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  font-family: 'Poppins', sans-serif;
  background-size: cover;
  background-position: center;
  color: white;
  height: 100%;
}

/* Navigation Bar Styling */
nav {
  background-color: #222940;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  flex-wrap: nowrap; /* Ensure no wrapping */
  overflow: hidden; /* Prevent content from overflowing */
}

nav:hover {
  background-color: #1e2235;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.8);
}

/* Logo Styling */
.logo {
  flex-shrink: 0;
}

.logo img {
  height: 50px;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1);
}

/* Navigation Links Styling */
.nav-links {
  display: flex;
  gap: 14px;
  list-style: none;
  transition: all 0.3s ease-in-out;
  flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-links li {
  font-size: 20px;
  font-weight: 700;
}

.nav-links li a {
  text-decoration: none;
  color: #ffcc00;
  padding: 8px 12px;
  border: 3px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.nav-links li a:hover {
  border-color: #ffcc00;
  background-color: rgba(255, 204, 0, 0.3);
  transform: scale(1.05);
}

/* Profile Section Styling */
.profile {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 30px; /* Increased spacing for clarity */
}

.profile-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #ffcc00;
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.profile-avatar:hover {
  transform: scale(1.1);
  border-color: #ffd700;
}

.edit-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  padding: 0;
  transition: filter 0.3s, transform 0.3s;
}

.edit-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.edit-icon:hover {
  filter: brightness(85%);
  transform: scale(1.15);
}

.profile .username {
  color: #ffcc00;
  font-size: 18px;
  font-weight: 600;
  max-width: 155px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

/* User Stats Section Styling */
.user-stats {
  display: flex;
  align-items: center;
  gap: 25px; /* Increased gap for a balanced look */
  font-size: 16px;
  color: #ffcc00;
  flex-shrink: 0;
}

.user-stats .xp-bar-container {
  width: 110px;
  height: 12px;
  background-color: #444;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.6);
}

.user-stats #xp-bar {
  height: 100%;
  background-color: #ffcc00;
  width: 50%; /* Dynamically set in JS */
  transition: width 0.3s ease-in-out;
  border-radius: 6px 0 0 6px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .nav-links {
    flex-direction: row;
    gap: 12px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .profile {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }

  .profile-avatar {
    width: 35px;
    height: 35px;
  }

  .user-stats {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    font-size: 15px;
  }

  .user-stats .xp-bar-container {
    width: 80%;
  }

  .nav-links li {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .logo img {
    height: 40px;
  }

  .profile .username {
    display: none;
  }

  .edit-icon img {
    width: 20px;
  }

  .user-stats .xp-bar-container {
    width: 65%;
  }

  .nav-links {
    width: 100%;
    justify-content: space-around;
  }
}

/* Distinct Balance Section */
.balance-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Evenly spaces the elements */
  padding: 8px 15px; /* Slightly wider padding for balance */
  border-radius: 10px;
  gap: 12px;
}

.balance {
  color: #ffcc00;
  font-weight: 600;
  font-size: 20px;
  flex-grow: 1;
  text-align: center; /* Centers text within container */
}

.add-balance-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers image within button */
  padding: 4px; /* Ensures clickable area is balanced */
  transition: transform 0.2s ease-in-out, filter 0.2s;
  border-radius: 50%; /* Makes button circular */
}

.add-balance-button img {
  width: 24px;
  height: 24px;
  filter: brightness(0.85); /* Dimmed icon look */
}

.add-balance-button:hover {
  transform: scale(1.2);
  filter: brightness(1.2); /* Adds brightness on hover */
}

.video-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center; /* Centers image within button */
  padding: 4px; /* Ensures clickable area is balanced */
  transition: transform 0.2s ease-in-out, filter 0.2s;
  border-radius: 50%; /* Makes button circular */
}

.video-button img {
  height: 24px;
  width: auto;
  filter: brightness(0.85); /* Dimmed icon look */
}

.video-button:hover {
  transform: scale(1.2);
  filter: brightness(1.2); /* Adds brightness on hover */
}

.dollar-icon {
  height: 20px;
  width: auto;
  margin-right: -5px;
}

/* Add a menu icon for smaller screens */
.menu-icon {
  display: none;
  font-size: 24px;
  color: #ffcc00;
  cursor: pointer;
  margin-left: auto;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  /* Hide navigation links initially, only show them when menu is active */
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .nav-links.active {
    display: flex;
  }
}

@media (max-width: 480px) {
  .profile .username {
    display: none;
  }

  .profile-avatar {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .nav-links {
    gap: 16px;
    font-size: 18px;
  }

  .nav-links li a {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .user-stats .xp-bar-container {
    width: 60%;
  }
}


/* Container Styling */
.container {
  margin-top: var(--nav-height, 73.6px);
  text-align: center;
  background-color: #263359;
  padding: 40px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.container h1 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffcc00;
}

.container .subheading {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
  color: #fff;
}

/* Button Styling */
.container .cta-button {
  background-color: #ffcc00;
  border: none;
  color: #242D48;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.container .cta-button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

/* Footer */
footer {
  background-color: #222940;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-links {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #ffcc00;
  font-weight: 600;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #e6b800;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 14px;
  color: #bbb;
}

/* Error Message Styling */
.error-message {
  color: red;
  font-weight: bold;
}

/* Cases Section */
.cases-section {
  padding: 40px 20px;
  text-align: center;
  background: linear-gradient(to bottom, #263359 0%, #263973 40px); /* Fade effect only over 20px */
  color: white;
  min-height: calc(30vh); /* Adjust height */
  font-family: 'Arial', sans-serif;
}

.cases-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Cases Container */
.cases-container {
  display: flex;
  gap: 25px;
  padding: 20px;
  justify-content: center; /* Center items */
  flex-wrap: wrap; /* Allow wrapping */
  max-width: 90%; /* Limit width to 90% of parent */
  margin: 0 auto; /* Center the container */
  overflow-x: auto; /* Enable horizontal scrolling for smaller screens */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #ffcc00 transparent; /* Custom scrollbar colors */
}

/* Hide scrollbar for WebKit browsers */
.cases-container::-webkit-scrollbar {
  height: 8px;
}

.cases-container::-webkit-scrollbar-thumb {
  background-color: #ffcc00;
  border-radius: 10px;
}

/* Case Item Styling */
.cases-container .case-item {
  background-color: #3b4b71;
  border: 2px solid #ffcc00;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 160px;
  flex: 0 0 auto;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.cases-container .case-item img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  object-fit: cover;
}

.cases-container .case-item p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.cases-container .case-item button {
  background-color: #ffcc00;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  color: #263973;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cases-container .case-item button:hover {
  background-color: #ffc107;
}

/* Hover Effect on Case Items */
.cases-container .case-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}

.cases-grid div {
  border: 2px solid #ffcc00;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.3s;
}

.case-item.not-owned {
  background-color: #333;
}

.case-item.owned {
  background-color: #28a745;
  color: white;
  border-color: #fff;
}

.case-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.7);
}

.case-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.case-button:hover {
  background-color: #0056b3;
}

.case-item.owned .case-button {
  background-color: #28a745;
}

.case-item.owned .case-button:hover {
  background-color: #218838;
}

.cases-section .case-item img {
  width: 100%;
  border-radius: 5px;
}

.cases-grid .case-item img {
  height: 160px;
  max-width: 100%;  /* Ensure the image doesn't exceed the parent's width */
}

.case-item h3 {
  font-size: 18px;
  color: #ffcc00;
}

.case-item p {
  font-size: 16px;
  color: #fff;
  padding: 8px 12px;
  margin: 0;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

/* Footer */
footer {
  background-color: #222940;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  font-size: 16px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-links {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #ffcc00;
  font-weight: 600;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #e6b800;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 14px;
  color: #bbb;
}

/* Responsive Footer */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 15px;
  }
}

/* Tooltip Styling */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Root Variables for Theme Customization */
:root {
  --primary-bg: #1e2b5a;
  --secondary-bg: #263973;
  --highlight-bg: #344b83;
  --text-color: #fff;
  --accent-color: #ffcc00;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

/* Global Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: var(--primary-bg);
  color: var(--text-color);
}

/* Clicker Container */
.clicker-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  margin-top: var(--nav-height, 73.6px); /* Takes into account the nav height */
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
  padding: 20px;
  gap: 20px;
}

/* Clicker Section */
.clicker {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: var(--secondary-bg);
  border-radius: 15px;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: background-color 0.3s ease;
}

.case-history-p.white { color: white; }
.case-history-p.orange { color: orange; }
.case-history-p.red { color: red; }


.clicker:hover {
  background-color: #2a4a77;
}

/* Balance Display */
.clicker-balance {
  font-size: 32px;
  color: var(--accent-color);
  margin-bottom: 20px;
  font-weight: 700;
  text-shadow: 2px 2px 5px var(--shadow-color);
}

/* Clickable Area */
.clickable-area {
  cursor: pointer;
  padding: 25px;
  border-radius: 12px;
  background: linear-gradient(145deg, #1e3a5f, #2a4a77);
  color: var(--text-color);
  text-align: center;
  max-width: 300px;
  font-size: 24px;
  font-weight: 600;
  box-shadow: 5px 5px 15px var(--shadow-color), inset 1px 1px 2px rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.clickable-area:hover {
  background: linear-gradient(145deg, #2a4a77, #1e3a5f);
  transform: scale(1.1);
  box-shadow: 6px 6px 20px var(--shadow-color);
}

/* Floating Number Animation */
.floating-number {
  position: absolute;
  color: var(--accent-color);
  font-weight: bold;
  animation: floatUp 0.5s ease-out forwards;
}

@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

/* Bonus Bar */
.bonus-bar {
  width: 100%;
  height: 12px;
  background-color: #e64a19;
  border-radius: 5px;
  margin-top: 10px;
}

/* Clicker Stats */
.clicker-stats {
  margin-top: 20px;
  text-align: center;
}

.clicker-stats p {
  margin: 8px 0;
  font-size: 18px;
}

/* Case History Section */
.case-history {
  flex: 0.4;
  background-color: var(--highlight-bg);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 10px var(--shadow-color);
  overflow-y: auto;
  text-align: center;
  max-height: 70vh;
  transition: background-color 0.3s ease;
}

.case-history:hover {
  background-color: #3b558b;
}

.case-history h3 {
  font-size: 26px;
  margin-bottom: 15px;
  text-align: center;
  color: var(--accent-color);
  text-shadow: 2px 2px 5px var(--shadow-color);
}

.case-history-p {
  font-size: 18px;
  margin: 8px 0;
  color: red;
}

.case-count {
  font-size: 18px;
  margin: 8px 0;
  color: white;
}

/* Case History List */
.case-history ul {
  list-style: none;
  padding: 0;
}

.case-history ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: var(--secondary-bg);
  margin-bottom: 10px;
  border-radius: 8px;
  color: var(--accent-color);
  font-weight: 600;
  transition: transform 0.2s ease;
}

.case-history ul li:hover {
  transform: scale(1.05);
}

.case-history ul li img {
  margin-left: 10px;
  height: 60px;
  border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .clicker-container {
    flex-direction: column;
  }

  .case-history {
    max-height: 50vh;
  }

  .clickable-area {
    font-size: 20px;
  }
}


/* Button Styling */
.case-button {
  background-color: #e6b800;
  color: #242D48;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.case-button:hover {
  background-color: #e64a19;
  transform: translateY(-2px);
}

.case-button:active {
  background-color: #d84315;
  transform: translateY(0);
}

/* Inventory Section */
.inventory-section {
  padding: 20px;
  background-color: #263973;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  margin-top: var(--nav-height, 73.6px);
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height based on screen size */
}

.inventory-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffcc00;
  text-align: center;
}

.filter-section {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: center;
}

.filter-section label {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
}

.filter-section input {
  padding: 8px;
  background-color: #333;
  color: white;
  border: 1px solid #ffcc00;
  border-radius: 5px;
  margin-top: 5px;
}

.sort-button,
.clear-button {
  background-color: #ffcc00;
  border: 0;
  padding: 9px 12px;
  font-size: 14px;
  color: #242D48;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
}

.sort-button:hover,
.clear-button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

/* Active or Focused State */
.sort-button:focus,
.clear-button:focus,
.sort-button:active,
.clear-button:active {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 204, 0, 0.5);
}

/* Inventory Items */
.inventory-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.inventory-item {
  border: 2px solid rgba(255, 204, 0, 0.8); /* Slightly transparent border */
  border-radius: 8px; /* More subtle rounding */
  padding: 10px; /* Slightly reduced padding for a compact look */
  text-align: center;
  width: 150px; /* Keep the width the same */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Softer shadow */
  transition: transform 0.2s, box-shadow 0.3s;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for better contrast */
}

.inventory-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* Enhance shadow on hover */
}

.inventory-item img {
  width: 100%;
  border-radius: 4px; /* Reduced rounding for a sleeker look */
  margin-bottom: 8px; /* Adjusted margin */
}

.inventory-item h3 {
  font-size: 16px; /* Keep the font size */
  color: #ffcc00; /* Maintain color */
  margin: 5px 0;
  font-weight: 500; /* Medium font weight for emphasis */
}

.inventory-item p {
  font-size: 14px; /* Keep the font size */
  color: #fff; /* Maintain color */
  margin: 0;
}

/* New styling for the price to make it more prominent */
.inventory-item .price {
  font-size: 18px; /* Increased font size for price */
  font-weight: 700; /* Bolder font for emphasis */
  color: #4caf50; /* Green color for price */
  margin: 8px 0; /* Added margin for spacing */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle text shadow for depth */
}

/* Add quality styling */
.inventory-item .quality {
  font-size: 12px; /* Smaller font size for quality */
  color: #ccc; /* Gray color for less emphasis */
  margin: 4px 0; /* Adjusted margin for spacing */
}

/* Sell Button Styling */
.sell-button {
  background-color: #e57373; /* Red background for a strong call-to-action */
  color: white; /* White text for contrast */
  padding: 10px 40px; /* Increased padding for a more substantial look */
  border: none; /* No border for a cleaner look */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Larger font size for better visibility */
  font-weight: bold; /* Bold text for emphasis */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  margin-top: 10px; /* Margin for spacing */
}

/* Hover and Active States for Sell Button */
.sell-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
  transform: scale(1.05); /* Slight scale up effect */
}

/* Focus and Active State */
.sell-button:focus,
.sell-button:active {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); /* Red shadow for focus state */
}



/* Case Opener Section */
.case-opener {
  width: 100%;
  padding-top: 40px;
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height based on screen size */
  margin-top: var(--nav-height, 73.6px);
  background-color: #263973;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.case-opener h2 {
  font-size: 32px;
  margin-bottom: 25px;
  color: #ffcc00;
}

.case-opener select {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #ffcc00;
  background-color: #444;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.case-opener button {
  background-color: #ffcc00;
  border: none;
  color: #242D48;
  padding: 0px 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  display: inline-block;
  margin: 15px 10px;  /* Adds margin between buttons */
}

.case-opener button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

/* Add space between buttons */
.case-opener .buy-case,
.case-opener .open-case {
  margin: 15px auto;
}

/* Case Image */
.case-opener img {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  width: auto;
  height: 60%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow to the image */
}

/* Result Section */
.case-opener .result {
  margin-top: 30px;
  padding: 25px;
  border: 2px solid rgba(255, 204, 0, 0.5);
  border-radius: 16px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px) saturate(180%);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #fff;
  transition: all 0.3s ease;
}

.case-opener .result h3 {
  font-size: 24px;
  color: #ffcc00;
}

.case-opener .result p {
  font-size: 18px;
  color: white;
  margin: 0;
}

/* For the rarity color background */
.case-opener .result {
  border: 2px solid rgba(255, 204, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
}


/* Cases Grid */
.case-choice {
  padding: 20px;
  text-align: center;
  background-color: #263973;
  margin-top: var(--nav-height, 73.6px);
}

.case-choice h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffcc00;
  text-align: center;
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.case-name {
  font-size: 16px;
  margin: 10px 0;
  font-weight: bold;
}

/* Page Layout */
.games-page {
  margin-top: var(--nav-height, 73.6px);
  padding: 20px;
  background-color: #263973; /* Dark blue background */
  color: white; /* White text color */
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
  font-family: 'Poppins', sans-serif;
}

/* Title Styling */
.games-title h1 {
  font-size: 40px;
  margin-bottom: 10px;
  color: #ffcc00; /* Gold color */
  text-align: center;
}

.games-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: #ccc; /* Light gray text */
  text-align: center;
}

/* Game Container */
.games-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Responsive grid */
  gap: 10px; /* Space between tiles */
  justify-items: center;
}

/* Game Tiles */
.game-tile {
  position: relative; /* Position for overlay content */
  width: 280px; /* Fixed width */
  height: 280px; /* Fixed height */
  border-radius: 15px;
  overflow: hidden; /* Ensure content stays within tile */
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box; /* Include padding and border in size */
  background-size: cover; /* Cover the entire tile */
  background-position: center; /* Center the image */
  display: flex;
  align-items: flex-end; /* Align content at the bottom */
  padding: 15px;
}

/* Game Tile Hover Effect */
.game-tile:hover {
  transform: translateY(-5px); /* Slight upward movement */
  box-shadow: 0 8px 20px rgba(255, 204, 0, 0.7); /* Glow effect */
}

/* Game Content Overlay */
.game-tile-content {
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  border-radius: 10px;
  padding: 10px;
  width: 100%; /* Ensure it spans the entire tile */
  text-align: center;
}

/* Game Name */
.game-name {
  font-size: 24px;
  color: #ffcc00;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  margin: 5px 0;
}

/* Game Status Text */
.game-status {
  font-size: 16px;
  color: #ddd;
}

/* Tile Colors Based on Status */
.game-tile.available {
  border: 2px solid rgba(72, 187, 120, 0.5); /* Green border */
}

.game-tile.comingSoon {
  border: 2px solid rgba(255, 193, 7, 0.5); /* Amber border */
}

.game-tile.inDevelopment {
  border: 2px solid rgba(220, 53, 69, 0.5); /* Red border */
}

/* Responsive Design */
@media (max-width: 768px) {
  .game-tile {
    height: auto; /* Allow height to adjust */
  }

  .game-tile-content {
    padding: 15px;
  }
}

.about-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #263973;
  margin-top: var(--nav-height, 73.6px);
}

/* Header Section */
.about-header {
  text-align: center;
  margin-bottom: 50px;
}

.about-header h1 {
  font-size: 50px;
  color: #ffcc00;
  font-weight: 700;
  margin-bottom: 10px;
}

.about-header p {
  font-size: 22px;
  color: #fff;
  font-weight: 400;
}

/* Section Headers */
.mission-section h2, 
.features-section h2, 
.team-section h2, 
.technology-section h2, 
.contact-section h2 {
  font-size: 32px;
  color: #ffcc00;
  border-bottom: 2px solid #ffcc00;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Section Containers */
.mission-section, 
.features-section, 
.team-section, 
.technology-section, 
.contact-section {
  margin: 50px 0;
}

/* Feature List */
.feature-list, 
.tech-stack {
  list-style: none;
  padding: 0;
}

.feature-list li, 
.tech-stack li {
  margin: 10px 0;
  font-size: 20px;
  color: #ffcc00;
}

/* Team Section */
.team-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member {
  text-align: center;
  max-width: 200px;
  background-color: #444;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #ffcc00;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.team-member img {
  border-radius: 50%;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
  border: 2px solid #ffcc00;
}

.team-member h3 {
  font-size: 22px;
  color: #ffcc00;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 18px;
  color: #fff;
}

.team-member a {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: #ffcc00;
}

.team-member a:hover {
  text-decoration: underline;
  color: #e6b800;
}

/* Contact Section */
.contact-section a {
  color: #ffcc00;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
  color: #e6b800;
}

/* Responsive Design */
@media (max-width: 768px) {
  .team-grid {
    flex-direction: column;
    align-items: center;
  }

  .about-header h1 {
    font-size: 40px;
  }

  .about-header p {
    font-size: 18px;
  }
}

.contact-page {
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  background-color: #263359;
  color: white;
  margin-top: var(--nav-height, 73.6px); /* Takes into account the nav height */
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
}

.contact-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffcc00;
}

.contact-header p {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 2rem;
}

.contact-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.email-contact h2,
.contact-form h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.email-list p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.email-list a {
  color: #3498db;
  text-decoration: none;
}

.email-list a:hover {
  text-decoration: underline;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: inherit;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3498db;
}

textarea {
  resize: none;
  height: 100px;
}

/* Privacy Policy Specific Styling */
.privacy-container {
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  background-color: #263359;
  color: white;
  margin-top: var(--nav-height, 73.6px); /* Takes into account the nav height */
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
}

.privacy-container h1 {
  font-size: 32px; /* Increases the size of the main heading */
  margin-bottom: 20px; /* Adds space below the heading */
  text-align: center; /* Centers the heading */
  color: #ffcc00;
}

.privacy-container p.subheading {
  font-size: 18px; /* Sets a slightly larger font size for subheading */
  margin-bottom: 20px; /* Adds space below the subheading */
  text-align: center; /* Centers the subheading */
}

.privacy-container section {
  margin-bottom: 40px; /* Space between sections */
}

.privacy-container section h2 {
  font-size: 24px; /* Adjusts the size for section headings */
  font-weight: 600; /* Bold font for section headings */
  margin-bottom: 10px; /* Space below section headings */
  color: #ffcc00; /* Highlights section headings in yellow */
}

.privacy-container section p,
.privacy-container section ul {
  font-size: 16px; /* Sets font size for paragraphs and lists */
  line-height: 1.6; /* Improves line spacing for readability */
  margin-bottom: 10px; /* Space below paragraphs and lists */
  color: #ffffff; /* Sets text color to white */
}

.privacy-container section ul {
  list-style-type: disc; /* Uses disc bullets for lists */
  margin-left: 20px; /* Indents lists */
}

.privacy-container section ul li {
  margin-bottom: 10px; /* Space below each list item */
}

.privacy-container a {
  color: #3498db;
  text-decoration: none;
}

/* Shop Section */
.shop-section {
  padding: 60px 20px;
  background: radial-gradient(circle at top, #0d1117, #1b1f2a);
  margin-top: var(--nav-height, 73.6px); /* Takes into account the nav height */
  min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.shop-title {
  color: #f5b700;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 0 5px 15px rgba(255, 183, 0, 0.5);
  animation: pulse_shop 1.5s infinite alternate;
}

/* Currency Grid */
.currency-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
}

/* Currency Tiles - Different Sizes */
.currency-tile {
  border-radius: 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  background: linear-gradient(135deg, #262d44, #3a4263);
  border: 2px solid transparent;
  background-clip: padding-box;
}

.currency-tile.small {
  grid-row: span 1;
}

.currency-tile.medium {
  grid-row: span 2;
}

.currency-tile.large {
  grid-row: span 3;
}

.currency-tile:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.9);
  border: 2px solid #ffd700;
}

/* Image Styling */
.currency-image {
  width: 100%;
  height: fit-content;
  object-fit: cover;
  border-bottom: 3px solid rgba(255, 215, 0, 0.5);
}

/* Content inside the tiles */
.tile-content {
  padding: 20px;
  margin-bottom: 30px;
  color: #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pack-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ffeb3b;
}

.pack-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.pack-description {
  font-size: 16px;
  color: #b0bec5;
}

/* Text colors for pack details */
.green-text {
  color: #4caf50;
}

.blue-text {
  color: #2196f3;
}

.gold-text {
  color: #ffd700;
}

.silver-text {
  color: #c0c0c0;
}

.bronze-text {
  color: #cd7f32;
}

.purple-text {
  color: #9c27b0;
}

.orange-text {
  color: #ff9800;
}

.yellow-text {
  color: #ffc107;
}

.red-text {
  color: #f44336;
}

/* Price Button */
.tile-price {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(45deg, #ff9800, #ffc107);
  color: #1e1e2e;
  padding: 10px 15px;
  border-radius: 15px;
  font-weight: 700;
  font-size: 20px;
  transition: background 0.3s, transform 0.2s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.tile-price:hover {
  background: linear-gradient(45deg, #f57c00, #ff9800);
}

/* Category Titles */
.category-title {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 15px;
  color: #e3f2fd;
  text-align: center;
  text-shadow: 0 3px 10px rgba(227, 242, 253, 0.7);
}

/* Animations */
@keyframes pulse_shop {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.05);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .currency-grid {
    grid-template-columns: 1fr;
  }

  .currency-tile.small,
  .currency-tile.medium,
  .currency-tile.large {
    grid-row: span 1;
  }

  .currency-image {
    height: 150px;
  }

  .tile-content {
    gap: 5px;
  }

  .pack-title {
    font-size: 24px;
  }

  .pack-description {
    font-size: 14px;
  }

  .tile-price {
    font-size: 18px;
  }
}

.ad-banner {
  margin-top: 20px;
}

/* Lucky Wheel Modal Container */
.lucky-wheel-cont {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for focus */
  z-index: 9999;
}

/* Modal Content */
.modal-content-wheel {
  padding: 25px;
  border-radius: 15px;
  max-width: 650px;
  width: 90%;
  background: #333957; /* Darker background for contrast */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6);
  text-align: center;
}

/* Title Styling */
.modal-content-wheel h2 {
  font-size: 1.8rem;
  color: #ffdf5d; /* Bright golden color for excitement */
  margin-bottom: 15px;
}

/* Wheel Container */
.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 320px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f3f3f3;
  border: 12px solid #ffcc00; /* Golden border */
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}

/* Main wheel */
.wheel {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  background: radial-gradient(circle at 50%, #ff7f50, #ff6347, #ff4500); /* Radial gradient for more depth */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4), inset 0 4px 20px rgba(255, 255, 255, 0.2); /* Soft outer and inner shadows */
  animation: pulseEffect 2s infinite alternate; /* Pulsing animation for more dynamic effect */
}

/* Individual segments */
.wheel .segment {
  width: 100%;
  height: 100%;
  position: absolute;
  clip-path: polygon(50% 50%, 100% 0%, 100% 100%); /* Triangular segments */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Wheel Choice Container */
.wheel-choice {
  display: flex;
  align-items: center; /* Centers children vertically */
  justify-content: center; /* Centers children horizontally if needed */
  position: relative;
}

/* Spin Button */
.spin-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.spin-button img {
  width: auto;
  height: 70px;
  transition: transform 0.3s ease;
}


/* Scale effect on hover */
.spin-button:hover img {
  transform: scale(1.2); /* Slightly enlarges on hover */
}


/* Pointer Styling */
.left-pointer {
  position: absolute;
  left: 15%; /* Position it close to the left side of the wheel */
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  border-style: solid;
  border-width: 30px 20px 0 20px;
  border-color: #ff6347 transparent transparent transparent;
  background: linear-gradient(to top, #ff7f7f, #ff6347);
  z-index: 1;
}

.right-pointer {
  position: absolute;
  right: 15%; /* Position it close to the right side of the wheel */
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  border-style: solid;
  border-width: 0 20px 30px 20px;
  border-color: transparent transparent #ffdf5d transparent;
  background: linear-gradient(to bottom, #ffe066, #ffdf5d);
  z-index: 1;
}

/* Left and Right Buttons */
.left-button,
.right-button {
  background-color: transparent; /* Transparent background */
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Fits image size */
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth rotation animation */
}

.close-btn {
  background-color: #ffcc00;
  border: 0;
  padding: 9px 12px;
  font-size: 14px;
  color: #242D48;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
}

/* Left Button Image */
/* Left Button Image */
.left-button img {
  width: auto;
  margin-left: 150%;
  height: 60px;
  transform: rotate(90deg);
  animation: idleAnimationLeft 6s ease-in-out infinite;
}

/* Right Button Image */
.right-button img {
  width: auto;
  margin-right: 150%;
  height: 60px;
  transform: rotate(-90deg);
  animation: idleAnimationRight 6s ease-in-out infinite;
}

/* Idle Animation for Left Button */
@keyframes idleAnimationLeft {
  0%, 100% {
    transform: rotate(90deg) scale(1);
  }
  25% {
    transform: rotate(95deg) scale(1.05);
  }
  50% {
    transform: rotate(90deg) scale(1.1);
  }
  75% {
    transform: rotate(85deg) scale(1.05);
  }
}

/* Idle Animation for Right Button */
@keyframes idleAnimationRight {
  0%, 100% {
    transform: rotate(-90deg) scale(1);
  }
  25% {
    transform: rotate(-85deg) scale(1.05);
  }
  50% {
    transform: rotate(-90deg) scale(1.1);
  }
  75% {
    transform: rotate(-95deg) scale(1.05);
  }
}

/* Rotate Animation on Hover */
.right-button:hover {
  transform: rotate(20deg); /* Slight rotation for a dynamic effect */
}

.left-button:hover {
  transform: rotate(-20deg);
}

.prize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 190px;
  transform: rotate(90deg);
  padding: 2px;
  border-top: 4px solid #ffdf5d; /* Golden border for a premium feel */
  border-radius: 30px; /* Soft rounded edges */
  box-shadow: inset 0 5px 10px rgba(255, 215, 0, 0.6); /* Golden glow at the top only */
  transition: transform 0.3s ease; /* Smooth scaling on hover */
}

.prize img {
  width: 45%;
  height: 45%;
  margin-top: 10px;
  margin-bottom: 10px; /* Space between image and text */
  border-radius: 50%; /* Rounded edges for a softer look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Image shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.prize img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

.prize h3 {
  color: #fff; /* White or light text for visibility */
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 2px 2px;
  border-radius: 5px;
}

/* Message Styling */
.wheel-message {
  margin-bottom: 20px;
  color: #ffd700; /* Bright golden color for visibility */
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to right, #333957, #4b4f73); /* Gradient background for depth */
  padding: 10px 15px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded edges for smooth design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Subtle shadow for better focus */
  border: 2px solid #ffdf5d; /* Golden border to match the theme */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth hover effect */
}
