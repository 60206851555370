/* General Casino Section Styling */
.casino-section-unique {
    margin-top: var(--nav-height, 73.6px); /* Takes into account the nav height */
    min-height: calc(100vh - var(--nav-height, 73.6px) - var(--footer-height, 205.2px)); /* Adjust height */
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #fff;
    font-size: 24px;
    font-family: 'Arial', sans-serif; /* Clean and modern font */
    text-align: center;
    position: relative; /* Allows for overlay effects */
    overflow: hidden; /* Prevent scroll for fullscreen immersion */
    box-sizing: border-box; /* Ensure padding does not affect dimensions */
  }
  
  /* Slot Machine Container */
  .slot-machine-container {
    position: relative;
    width: 90%; /* Responsive width */
    max-width: 350px; /* Prevents excessive scaling */
    height: auto;
    aspect-ratio: 7 / 11; /* Keeps consistent proportions */
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5); /* Add depth */
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
  }
  
  /* Slot Machine Image */
  .slot-machine {
    width: 100%;
    height: auto;
    display: block;
    animation: glow 2s infinite ease-in-out; /* Gentle glowing effect */
  }
  
  /* Prize Icons */
  .prize-icon {
    width: 35px; /* Adjust size dynamically */
    max-width: 35px;
    height: auto;
    z-index: 10;
    position: absolute;
    animation: prizeBounce 0.8s ease-out; /* Bouncing reveal effect */
  }
  
  /* Button Styling */
  .button-unique {
    padding: 15px 5%;
    background: linear-gradient(45deg, #ffcc00, #ff8800);
    color: #222940;
    font-weight: bold;
    font-size: 18px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 10px rgba(255, 204, 0, 0.5);
    margin-top: 20px;
  }
  
  .button-unique:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 15px rgba(255, 204, 0, 0.7);
  }
  
  /* Ticket Popup */
  .ticket-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background: rgba(0, 0, 0, 0.85);
    border: 3px solid #ffcc00;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8);
    z-index: 10;
    animation: slideDown 0.5s ease-out; /* Popup slide-down animation */
  }
  
  .ticket-popup h3 {
    color: #ffcc00;
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .ticket-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 15px;
    align-items: center;
  }
  
  .ticket-option {
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .ticket-option img {
    width: 60px;
    height: 60px;
    margin-bottom: 8px;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: border 0.3s ease;
  }
  
  .ticket-option:hover img {
    border: 2px solid #ffcc00;
  }
  
  .ticket-option:hover {
    transform: scale(1.2);
    box-shadow: 0 6px 15px rgba(255, 204, 0, 0.5);
  }
  
  .ticket-option p {
    font-size: 16px;
    color: white;
  }
  
  /* Media Queries for Responsiveness */
  @media screen and (max-width: 768px) {
    .casino-section-unique {
      padding: 10px;
    }
  
    .slot-machine-container {
      max-width: 300px;
      aspect-ratio: 7 / 12; /* Slightly taller proportions on smaller screens */
    }
  
    .button-unique {
      font-size: 16px;
      padding: 10px 5%;
    }
  
    .prize-icon {
      width: 10%;
      max-width: 25px;
    }
  
    .ticket-popup {
      padding: 15px;
      max-width: 90%;
    }
  
    .ticket-popup h3 {
      font-size: 20px;
    }
  
    .ticket-option img {
      width: 50px;
      height: 50px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .slot-machine-container {
      max-width: 250px;
    }
  
    .prize-icon {
      width: 15%;
    }
  
    .button-unique {
      font-size: 14px;
      padding: 8px 5%;
    }
  
    .ticket-popup h3 {
      font-size: 18px;
    }
  
    .ticket-option img {
      width: 40px;
      height: 40px;
    }
  }
  
  /* Animations */
  @keyframes glow {
    0%, 100% {
      filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.3));
    }
    50% {
      filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.7));
    }
  }
  
  @keyframes prizeBounce {
    0% {
      transform: scale(0.8) translateY(-50%);
      opacity: 0;
    }
    50% {
      transform: scale(1.2) translateY(0%);
      opacity: 1;
    }
    100% {
      transform: scale(1) translateY(0%);
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translate(-50%, -60%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
}

.jackpot-item {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensures it's above other elements */
  text-align: center;
  background: linear-gradient(145deg, #ffd700, #ffcc33);
  border: 5px solid #ff8c00;
  border-radius: 20px;
  padding: 25px;
  max-width: 400px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6), 0 4px 15px rgba(255, 204, 0, 0.5);
  animation: jackpotFlash 2s infinite alternate;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.jackpot-item img {
  width: 120px;
  height: auto;
  margin: 20px 0;
  border: 3px solid #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  animation: itemBounce 1s ease-in-out infinite alternate;
}

.jackpot-item h2 {
  color: #ff4500;
  font-size: 28px;
  margin-bottom: 15px;
  text-shadow: 0 4px 10px rgba(255, 69, 0, 0.6), 0 2px 5px rgba(0, 0, 0, 0.4);
}

.jackpot-item p {
  font-size: 18px;
  margin: 5px 0;
  color: #222;
}

/* Animations */
@keyframes jackpotFlash {
  0% {
    background: linear-gradient(145deg, #ffd700, #ffcc33);
  }
  100% {
    background: linear-gradient(145deg, #ffcc33, #ffd700);
  }
}

@keyframes itemBounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
