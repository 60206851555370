/* Modal Background */
.modal {
display: none;
position: fixed;
z-index: 1000;
left: 0;
top: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
justify-content: center;
align-items: center;
transition: opacity 0.3s ease;
align-items: center;
justify-content: center;
overflow: auto; /* Ensure scrolling if content overflows */
}

/* Modal Content Box */
.modal-content {
background-color: #242D48;
color: white;
padding: 20px;
border: 1px solid #888;
max-width: 40%; /* Ensures responsiveness */
border-radius: 8px;
text-align: center;
position: relative;
overflow-y: auto; /* Enable scrolling for large content */
margin: auto; /* Center the modal horizontally */
}

.modal-content-frick {
color: white;
padding-top: 15px;
max-width: 50%; /* Ensures responsiveness */
text-align: center;
position: relative;
margin: auto; /* Center the modal horizontally */
}

/* Close Button */
.modal-content .close {
color: #fff;
font-size: 32px;
font-weight: bold;
position: absolute;
top: 15px;
right: 15px;
cursor: pointer;
}

.modal-content .close:hover {
color: #e74c3c; /* Red hover effect */
}

/* Title Styling */
.modal-content h2 {
font-size: 24px;
margin-bottom: 20px;
font-weight: 600;
color: #f39c12; /* Yellow title */
}

/* Input Fields */
.modal-content label {
display: block;
margin-bottom: 8px;
font-size: 14px;
color: #ecf0f1;
}

.modal-content input[type="text"],
.modal-content input[type="password"] {
width: 100%;
padding: 12px;
margin-top: 8px;
margin-bottom: 20px;
border-radius: 8px;
border: 1px solid #7f8c8d;
background-color: #34495e;
color: white;
font-size: 16px;
transition: border-color 0.3s ease;
}

.modal-content input[type="text"]:focus,
.modal-content input[type="password"]:focus {
border-color: #f39c12;
outline: none;
}

/* Error and Success Messages */
.modal-content .error-message {
color: #e74c3c;
font-size: 14px;
margin: 10px 0;
}

.modal-content .success-message {
color: #2ecc71;
font-size: 14px;
margin: 10px 0;
}

/* Submit Button */
.modal-content button {
background-color: #f39c12;
border: none;
color: #2c3e50;
padding: 12px 20px;
font-size: 18px;
cursor: pointer;
border-radius: 8px;
transition: background-color 0.3s, transform 0.2s;
}

.modal-content button:hover {
background-color: #e67e22; /* Slightly darker yellow */
transform: translateY(-2px);
}

.modal-content button:active {
transform: translateY(1px); /* Slight click effect */
}

/* Image Gallery */
.image-gallery {
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 15px;
margin: 20px 0;
}

.image-gallery img {
width: 60px;
height: 60px;
cursor: pointer;
transition: transform 0.2s ease, box-shadow 0.3s ease;
border-radius: 8px;
}

.image-gallery img:hover {
transform: scale(1.1);
box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.selected-image {
border: 3px solid #f39c12;
box-shadow: 0 0 15px rgba(243, 156, 18, 0.5);
transform: scale(1.15);
}

/* Toggle Auth Button */
.toggle-auth-button {
background: none;
border: none;
color: #f39c12;
font-weight: bold;
cursor: pointer;
text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 480px) {
.modal-content {
    width: 95%;
    padding: 20px;
}

.modal-content h2 {
    font-size: 20px;
}

.modal-content button {
    font-size: 16px;
}

.image-gallery img {
    width: 50px;
    height: 50px;
}
}

/* Password visibility button styling */
.modal-content input[type="password"] + button,
.modal-content input[type="text"] + button {
position: absolute;
right: 10px;
top: 50%;
transform: translateY(-50%);
background: none;
border: none;
cursor: pointer;
padding: 0;
}