/* Popup list container (top-right corner of the screen) */
.popup-list {
  position: fixed;
  top: calc(var(--nav-height, 73.6px) + 15px);
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Individual popup styling for a game-like look */
.lucky-wheel-popup,
.casino-popup {
  width: 65px;
  height: 65px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Centering the link inside popups */
.casino-popup a,
.lucky-wheel-popup a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none; /* Remove underline if any */
}

/* Image inside popups */
.lucky-wheel-popup img,
.casino-popup img {
  width: auto;
  height: 90%;
  object-fit: fill;
  transition: transform 0.3s ease;
}

/* Hover effects for popups */
.lucky-wheel-popup:hover,
.casino-popup:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 20px rgba(255, 204, 0, 0.6);
  background: linear-gradient(135deg, #ffd700, #ffcc00);
}

.lucky-wheel-popup:hover img,
.casino-popup:hover img {
  transform: scale(1.1);
}
